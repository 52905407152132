<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New User
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="Full Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="userData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <b-form-group
            label="Client"
            label-for="client"
          >
            <v-select
              v-model="userData.client_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="clientOptions"
              :clearable="false"
              :reduce="option => option.value"
              input-id="client"
            />
          </b-form-group>

          <!-- Field: Status -->
          <b-form-group
            label="Status"
            label-for="user-status"
          >
            <b-form-checkbox
              v-model="userData.status"
              switch
              inline
              value="1"
              unchecked-value="0"
            />
          </b-form-group>

          <!-- Field: CPV Profile -->
          <b-form-group
            label="Cpv profile"
            label-for="cpv-profile"
          >
            <v-select
              id="cpv-profile"
              v-model="userData.cpv_profiles"
              :options="cpvProfilesOptions"
              :reduce="val => val.id"
              label="name"
              multiple
            />
          </b-form-group>

          <!-- Role -->
          <validation-provider
            #default="validationContext"
            name="Role"
            rules="required"
          >
            <b-form-group
              label="Role"
              label-for="role"
              :state="validationContext.errors.length > 0 ? false:null"
            >
              <v-select
                id="role"
                v-model="userData.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="rolesOptions"
                :clearable="false"
                :reduce="option => option.value"
                input-id="role"
              />
              <b-form-invalid-feedback :state="validationContext.errors.length > 0 ? false:null">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroupAppend, BInputGroup, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import Roles from '@/common/Roles'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroupAppend,
    BInputGroup,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewClientSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      clientOptions: [],
      cpvProfilesOptions: [],
    }
  },
  computed: {
  },
  created() {
    this.$store.dispatch('tenders/fetchAllProfiles')
      .then(response => {
        this.cpvProfilesOptions = response.data
      })

    this.$store.dispatch('clients/fetchClientsAll')
      .then(response => {
        this.clientOptions = response.data.map(client => ({
          label: client.name,
          value: client.id,
        }))
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
  },
  setup(props, { emit }) {
    const blankUserData = {
      name: null,
      email: null,
      client_id: null,
      status: false,
      cpv_profiles: null,
      role: null,
    }

    const statusOptions = [
      {
        label: 'Active',
        value: 1,
      },
      {
        label: 'Inactive',
        value: 0,
      },
    ]

    const rolesOptions = [
      {
        label: 'Admin',
        value: 'Admin',
      },
      {
        label: 'TenderViewer',
        value: 'TenderViewer',
      },
      {
        label: 'TestUser',
        value: 'TestUser',
      },
      {
        label: 'TenderSearcher',
        value: 'TenderSearcher',
      },
      {
        label: 'TenderNotifier',
        value: 'TenderNotifier',
      },
    ]

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,
      rolesOptions,
      refFormObserver,
      getValidationState,
      resetForm,
      statusOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
